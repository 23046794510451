import * as React from "react";
import { Box, Button, Paper, Container, Typography } from "@mui/material";
import { typography } from "@mui/system";
import { useTheme } from "@mui/material";
// import Box from "@mui/material/Box";
// import landingImg from "../images/landing-img.png";
import "./LandingSection.css";
import { Link, animateScroll as scroll } from "react-scroll";
import useMediaQuery from "@mui/material/useMediaQuery";
// import colorscheme from "../constants/theme";

// Local filepaths --------------------------------------------------
// import landingImg from "../images/website_landing.png";
// import landingImgSmall from "../images/website_landing_small.png";
// import nameBackground from "../images/name-background.svg"; // not used
// import nameSvg from "../images/name.svg";
// -----------------------------------------------------------------------------------------------
const landingImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/website_landing.png";
const landingImgSmall =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/website_landing_small.png";
const nameSvg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/name.svg";

const ScrollLink = (props) => {
  return (
    <Link
      activeClass="active"
      to={props.to}
      spy={true}
      smooth={true}
      offset={-100}
      duration={500}
    >
      {props.children}
    </Link>
  );
};
export default function LandingSection({ loadedLandingImage }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("700"));

  return (
    <div className="placeHolder">
      <Box className="landing-container">
        {isMobile ? (
          <img
            id="background-img"
            src={landingImgSmall}
            alt="landing background"
            loading="eager"
          />
        ) : (
          <img
            id="background-img"
            className="fadeInExpand2-animation"
            src={landingImg}
            alt="landing background"
            loading="eager"
          />
        )}
        <Box className="landing-text fadeInExpand-animation">
          {/* <h3>WELCOME TO MY WEBSITE</h3> */}
          <Typography
            //   variant={props.isMobile ? "h4" : "h6"}
            //   align={props.isMobile ? "center" : "left"}
            className="top-text"
            variant="h5"
            align="left"
            color="primary"
            textTransform="uppercase"
            gutterBottom
          >
            Welcome to my website!
          </Typography>

          <img
            src={nameSvg}
            className="fadeInUp-animation"
            alt="landing page image"
          />
          {/* <h1>
          I'm
          <h1 className="name"> Mandy He </h1>
        </h1> */}
          {/* <div class="name-container">
            <img src={nameBackground} alt="paint stroke" />
            <h1 class="centered">Mandy He</h1>
        </div> */}
          <Typography
            //   variant={props.isMobile ? "h4" : "h6"}
            //   align={props.isMobile ? "center" : "left"}
            // variant="h5"
            className="my-desc"
            align="left"
            color="primary"
            gutterBottom
          >
            A recent Brown University computer science graduate passionate about
            visual computing and creating human-centered applications that blend
            art and technology.
          </Typography>
          {/* 
        <p>
          Student at Brown University studying Computer Science and Visual Art
        </p> */}
          <ScrollLink to="about-section">
            <Button variant="contained" size="large">
              More about me
            </Button>
          </ScrollLink>
        </Box>

        {/* <img src={landingImg} width="100%" /> */}
      </Box>
    </div>
  );
}
