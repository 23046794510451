import {
  Box,
  Button,
  IconButton,
  Paper,
  Container,
  Typography,
} from "@mui/material";

import { GitHub, LinkedIn, Email, GetApp } from "@mui/icons-material";



export default function Contact() {
  return (
    <Box height="3em">
      <a href="https://github.com/12345Mandy">
        <IconButton aria-label="github" size="large" color="primary">
          <GitHub transform="scale(1.5)" />
        </IconButton>
      </a>
      <a href="https://www.linkedin.com/in/he-mandy/">
        <IconButton aria-label="linkedin" size="large" color="primary">
          <LinkedIn transform="scale(1.5)" />
        </IconButton>
      </a>
      <a href="mailto:mandy_he@brown.edu">
        <IconButton aria-label="email" size="large" color="primary">
          <Email transform="scale(1.5)" />
        </IconButton>
      </a>
    </Box>
  );
}
