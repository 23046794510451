import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, Paper, Container, Typography } from "@mui/material";
import Contact from "../components/Contact";
import Link from "@mui/material/Link";

// Local filepaths --------------------------------------------------
// import AboutSVG from "../images/About_Me.svg";
// import ProfilePic from "../images/ProfilePic.jpg";
// --------------------------------------------------------------------------

const AboutSVG =
  "https://raw.githubusercontent.com/12345Mandy/images/e1020b22ff823d6eafe447c97aa62ac6bef63632/used_images/About_Me.svg";

const ProfilePic =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/ProfilePic.jpg";

export default function AboutMeSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1020"));
  // function renderContactSection() {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       flexDirection="column"
  //       margin="10%"
  //       marginTop="2%"
  //     >
  //       <Paper
  //         elevation={3}
  //         style={{
  //           // backgroundColor: "#4C626A",
  //           backgroundColor: "white",
  //           height: "20rem",
  //           borderRadius: "8px",
  //           margin: "10%",
  //         }}
  //       >
  //         <img
  //           src={ProfilePic}
  //           height="100%"
  //           style={{ borderRadius: "8px", margin: "5%" }}
  //           // width="100%"
  //         />
  //       </Paper>
  //       <Contact />
  //     </Box>
  //   );
  // }

  // function renderDescription() {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       flexDirection="column"
  //       marginRight="10%"
  //     >
  //       <Typography marginBottom="5%">
  //         ~ My name is Mandy He, and I’m a rising Sophomore studying Computer
  //         Science at Brown University. Having always been interested in art and
  //         tech, I love exploring the intersection between these two fields. ~
  //       </Typography>

  //       <Typography>
  //         ~ When my back aches from my stooping over my desk to study, I love
  //         arching back in my seat to hear a few satisfying cracks before
  //         prancing around my room. Besides that though, I also love to eat,
  //         paint, collage, dance, read Webtoons, and listen to music! Sometimes,
  //         if I’m feeling really daring, I’ll eat, paint, dance AND listen to
  //         music at once :D ~
  //       </Typography>
  //     </Box>
  //   );
  // }
  // return (
  //   <>
  //     <img className="about-header" src={AboutSVG} alt="name" />
  //     <Box
  //       // display={isMobile ? "" : "flex"}
  //       display="flex"
  //       justifyContent="space around"
  //       alignItems="center"
  //       flexDirection="row"
  //       flexWrap="wrap"
  //     >
  //       {/* {renderContactSection()}
  //       {renderDescription()} */}
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         flexDirection="column"
  //         margin="10%"
  //         marginTop="2%"
  //       >
  //         <Paper
  //           elevation={3}
  //           style={{
  //             // backgroundColor: "#4C626A",
  //             backgroundColor: "white",
  //             height: "20rem",
  //             borderRadius: "8px",
  //             margin: "10%",
  //           }}
  //         >
  //           <img
  //             src={ProfilePic}
  //             height="100%"
  //             style={{ borderRadius: "8px", margin: "5%" }}
  //             // width="100%"
  //           />
  //         </Paper>
  //         <Contact />
  //       </Box>

  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         flexDirection="column"
  //         marginRight="10%"
  //       >
  //         <Typography marginBottom="5%">
  //           ~ My name is Mandy He, and I’m a rising Sophomore studying Computer
  //           Science at Brown University. Having always been interested in art
  //           and tech, I love exploring the intersection between these two
  //           fields. ~
  //         </Typography>

  //         <Typography>
  //           ~ When my back aches from my stooping over my desk to study, I love
  //           arching back in my seat to hear a few satisfying cracks before
  //           prancing around my room. Besides that though, I also love to eat,
  //           paint, collage, dance, read Webtoons, and listen to music!
  //           Sometimes, if I’m feeling really daring, I’ll eat, paint, dance AND
  //           listen to music at once :D ~
  //         </Typography>
  //       </Box>
  //     </Box>
  //   </>
  // );

  return (
    <div className="aboutMe">
      <img className="about-header" src={AboutSVG} alt="name" />
      {/* <img
        className="about-header"
        src="https://raw.githubusercontent.com/12345Mandy/images/e1020b22ff823d6eafe447c97aa62ac6bef63632/used_images/About_Me.svg"
        alt="name"
      /> */}
      <div className="project-masonry">
        <Box
          display={isMobile ? "" : "flex"}
          // display={true ? "" : "flex"}
          // justifyContent="space around"
          alignItems="center"
          flexDirection="row"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            margin={isMobile ? "2% 15%" : "10%"}
            marginTop="2%"
            // marginLeft={isMobile ? "15%" : ""}
            minWidth={isMobile ? "" : "300px"}
            // maxWidth={isMobile ? "350px" : ""}
          >
            {/* <Paper
              elevation={3}
              className="profilePic"
              // style={{
              //   // backgroundColor: "#4C626A",
              //   backgroundColor: "white",
              //   height: "20rem",
              //   // borderRadius: "8px",
              //   // margin: "10%",
              // }}
            > */}
            <img
              className="profilePic"
              src={ProfilePic}
              // height="100%"
              // style={{ borderRadius: "8px", margin: "5%" }}
              // width="100%"
            />
            {/* </Paper> */}
            <Contact />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="left"
            flexDirection="column"
            marginRight={isMobile ? "" : "10%"}
            margin={isMobile ? "5% 15%" : ""}
          >
            <Typography marginBottom="5%">
              I recently graduated from Brown University with{" "}
              <Link
                href="https://college.brown.edu/sites/default/files/2022-02/Concurrent%20Degree%20Program%20Application--Revised%20August%202022.docx%20fillable.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                bachelor's and master's
              </Link>{" "}
              degrees in computer science. With a lifelong passion for both art
              and technology, I find joy in exploring the intersection of these
              fields. My interests particularly lie in visual computing,
              encompassing computer vision, computer graphics, and AI/ML, as
              well as in creating human-centered applications that enhance
              people’s lives.
            </Typography>

            <Typography marginBottom="5%">
              Previously, I've worked on engaging features like Match Madness at{" "}
              <Link
                href="https://www.duolingo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Duolingo
              </Link>{" "}
              and on VR/MR and computer graphics related projects at{" "}
              <Link
                href="https://about.meta.com/realitylabs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Meta Reality Labs
              </Link>
              .
            </Typography>
          </Box>
        </Box>
        <Typography margin="0 5%" className="fun-aboutme">
          ~ When my spine aches from my stooping over my desk to study, I love
          arching back in my seat to hear a few satisfying cracks before
          prancing around my room. Besides that though, I also love to eat,
          paint, dance, read Webtoons, and listen to music! Sometimes, if I’m
          feeling really daring, I’ll eat, paint, dance AND listen to music at
          once :D ~
        </Typography>
      </div>
    </div>
  );
}
