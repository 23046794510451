import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function ProjectCard({ props }) {
  return (
    <Card sx={{ maxWidth: 600 }}>
      <a href={props.link} target="_blank">
        <CardActionArea>
          <CardMedia
            component="img"
            // height="140"
            image={`${props.img}?w=162&auto=format`}
            // image={`${props.loadedImg}?w=162&auto=format`}
            alt={props.title}
            loading=""
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              fontWeight="bold"
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
}
