// Local filepaths --------------------------------------------------
// import tesseract from "../images/tesseract_blender.gif";
// import inkImg from "../images/ink.gif";
// import compPhotoImg from "../images/compPhotoImg.png";
// import calvinImg from "../images/humanoid_standing_semibold.png";
// import graphicsLandscape from "../images/graphic-landscape.gif";
// import gesturifyVid from "../images/gesturifyVid.gif";
// import mixedRealityWindowImg from "../images/mixedRealityWindow.png";
// import PointCloudImg from "../images/pointcloudVR.png";
// import SongDataImg from "../images/SongDataImg.png";
// import SambhaliImg from "../images/SambhaliImg.png";
// import PartnerMatchingImg from "../images/PartnerMatchingImg.png";

// import yawnImg from "../images/art/Yawn.JPG";
// import dominoImg from "../images/art/domino.JPG";
// import DuckStamp from "../images/art/DuckStamp.jpg";
// import glassImg from "../images/art/glassArt.JPG";
// import danteKitchenImg from "../images/art/danteKitchen.jpg";
// import penImg from "../images/art/pen.JPG";

// ------------------------------------------------------------------
const inkImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/ink.gif";
const compPhotoImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/compPhotoImg.png";
const calvinImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/humanoid_standing_semibold.png";
const graphicsLandscape =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/graphic-landscape.gif";
const gesturifyVid =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/gesturifyImg.png";
// "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/gesturifyVid.gif";
const mixedRealityWindowImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/mixedRealityWindow.png";
const PointCloudImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/pointcloudVR.png";
const SongDataImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/SongDataImg.png";
const SambhaliImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/SambhaliImg.png";
const PartnerMatchingImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/PartnerMatchingImg.png";

const yawnImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/art/Yawn.JPG";
const dominoImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/art/domino.JPG";
const DuckStamp =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/art/DuckStamp.jpg";
const glassImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/art/glassArt.JPG";
const danteKitchenImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/art/danteKitchen.jpg";
const penImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/art/pen.JPG";

export const PROJECT_DATA = [
  {
    img: compPhotoImg,
    title: "Computational Long Exposure",
    link: "https://github.com/tlamlert/long-exposure",
    description:
      'Implemented the complex pipeline from Google Research\'s "Computational Long Exposure Mobile Photography" using nonproprietary tools, achieving high-quality results with Deepgaze and RAFT models for subject detection and motion prediction.',
  },
  {
    img: inkImg,
    title: "Ink Simulation",
    link: "https://github.com/12345Mandy/Ink-Simulation",
    description:
      "Simulated ink diffusion using C++ with Navier-Stokes equations and vorticity confinement. \n Scripted Blender rendering of particle positions for visualization using geometry nodes and animation features",
  },
  {
    img: calvinImg,
    title: "Text-Aware Diffusion for Policy Learning",
    link: "https://arxiv.org/abs/2407.01903",
    description:
      "[NeurIPS 2024 SUBMISSION] Collaborated on research leveraging pretrained text-to-image diffusion models for learning zero-shot policies specified by natural language prompts. ",
  },
  {
    img: gesturifyVid,
    title: "Gesturify",
    link: "https://github.com/kaisucode/troupe",
    description:
      "An augmented realty app that allows users to create special effects on real-time video feed with hand gestures. \nCo-developed with Kevin Hsu, Sophia Liu, and Tianran Zhang.",
  },
  {
    img: mixedRealityWindowImg,
    title: "Mixed Reality Window",
    link: "https://github.com/kaisucode/mixed-reality-window-streaming",
    description:
      "[MAB 2023 PUBLICATION] Prototyped real-time communication & networking software, using machine learning solutions and OpenCV to process camera feed and extract selfie-view videos.\nResearch project with Professor Ian Gonsher. ",
  },
  {
    img: PointCloudImg,
    title: "VR Point Cloud Data Visualization",
    link: "https://www.vrwiki.cs.brown.edu/vr-visualization-software/tutorials/dinovr-tutorial/dinovr-vs-unity-for-point-cloud-data-visualization",
    description:
      "Compared how two different software (Unity + XR Interaction Toolkit + Normcore vs DinoVR) visualized point cloud data in VR on a Meta Quest 2 Headset. ",
  },
  {
    img: graphicsLandscape,
    title: "Hilda",
    link: "https://github.com/kaisucode/hilda",
    description:
      "A stylized 3D scenery that contains terrains generated with Fractional Brownian Motion and Perlin Noise, as well as orbit camera movement and randomly spawned trees.\nCo-developed with Kevin Hsu and Mehek Jethani.",
  },

  //   {
  //     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
  //     title: "USC Manuscript Visualization Template",
  //     description:
  //       "Tinkered with USC's manuscript visualization template, attempted to improve user experience, added multiuser functionality to template, and wrote script to facillitate adding pages to experience",
  //   },
  {
    img: PartnerMatchingImg,
    title: "Partner Matching",
    link: "https://github.com/12345Mandy/PartnerMatching",
    description:
      "Created a partner matching site that allows users to design, create, and take custom surveys.\nMatched users with the stable-roommates algorithm utilizing Java and Firebase as the backend.\n Designed and implemented user interface, and handled user data and privacy with React, CSS, HTML, and Figma.",
  },
  {
    img: SongDataImg,
    title: "Song Data Analysis",
    link: "https://github.com/12345Mandy/SMAK_song_data_analysis",
    description:
      "Analyzed song data from 1958-2020 using Spotify's API to examine the relationship between pop music and time. \nThis project included data collection, exploratory analysis, hypothesis/problem statement, and confirmatory analysis/prototype development.\n Worked with Annabeth Stokely, Kiera Walsh, and Sophia Liu.",
  },
  {
    img: SambhaliImg,
    title: "Sambhali US",
    link: "https://www.sambhalius.org/",
    description:
      "Worked to increase site traffic through improvements in site layout, features, and flow.\n Produced and presented prototypes, research findings, and weekly design mockups to Board of Directors. \nCompleted as a member of Develop for Good: a nonprofit organization that pairs, trains and supports student volunteers as they develop technical product solutions for nonprofits.",
  },
];

export const ART_DATA = [
  {
    img: yawnImg,
    title: "A Pipe Dream",
    description:
      "An augmented realty app that allows users to create special effects on real-time video feed with hand gestures .\nCo-developed with Kevin Hsu, Sophia Liu, and Tianran Zhang.",
  },
  {
    img: dominoImg,
    title: "Domino",
    description:
      "Prototyped real-time communication & networking software, using machine learning solutions and OpenCV to process camera feed and extract selfie-view videos.\nResearch project with Professor Ian Gonsher. ",
    url: "",
  },
  {
    img: DuckStamp,
    title: "Rising Sun",
    description:
      "Compared how two different software (Unity + XR Interaction Toolkit + Normcore vs DinoVR) visualized point cloud data in VR on a Meta Quest 2 Headset. ",
  },
  {
    img: glassImg,
    title: "Through the Looking Glass",
    description:
      "A stylized 3D scenery that contains terrains generated with Fractional Brownian Motion and Perlin Noise, as well as orbit camera movement and randomly spawned trees.\nCo-developed with Kevin Hsu and Mehek Jethani.",
  },
  {
    img: danteKitchenImg,
    title: "Dante's Kitchen",
    description:
      "A stylized 3D scenery that contains terrains generated with Fractional Brownian Motion and Perlin Noise, as well as orbit camera movement and randomly spawned trees.\nCo-developed with Kevin Hsu and Mehek Jethani.",
  },
  {
    img: penImg,
    title: "Tower in Hometown",
    description:
      "A stylized 3D scenery that contains terrains generated with Fractional Brownian Motion and Perlin Noise, as well as orbit camera movement and randomly spawned trees.\nCo-developed with Kevin Hsu and Mehek Jethani.",
  },
];
