import { createTheme, responsiveFontSizes } from "@mui/material";

// const handleeFont = "'Handlee', cursive";
const openSansFont = "'Open Sans', sans-serif";
const faustina = "Faustina";
const lato = "Lato";
const raleway = "Raleway";
const merriweatherSans = "Merriweather Sans";

export const colorscheme = {
  navy: "#4C626A",
  white: "#ffffff",
};
// $grey: #4b4339;

const customizedTheme = createTheme({
  // breakpoints: {
  //   values: {
  //     // sm: 800,
  //     // tablet: 640,
  //     // laptop: 1024,
  //     // desktop: 1200,
  //   },
  // },
  palette: {
    primary: {
      main: colorscheme.navy,
    },
    secondary: {
      main: colorscheme.white,
    },
    background: {
      main: colorscheme.navy,
    },
    // text: {
    //   secondary: colorscheme.navy,
    // },
    // dark: colorscheme.black,
    // cream: {
    //   main: colorscheme.cream,
    // },
  },
  typography: {
    // fontFamily: openSansFont,
    // fontFamily: raleway,
    fontFamily: lato,
    // fontFamily: merriweatherSans,
    // fontFamily: faustina,
    allVariants: {
      color: colorscheme.navy,
      // color: colorscheme.maple
    },
    body1: {
      fontSize: "1rem",
    },
    // body2: {
    //   fontSize: "1rem",
    // },
    // h3: {
    //   backgroundImage: 'url("../images/fog.png")',
    //   backgroundSize: "cover",
    //   backgroundRepeat: "no-repeat",
    //   fontWeight: "lighter",
    //   fontSize: "1.5em",
    // },
  },
});

export const theme = responsiveFontSizes(customizedTheme);
