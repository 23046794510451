import logo from "./logo.svg";
import "./App.css";
import { Box, Button, Paper, Container, Typography } from "@mui/material";
import LandingSection from "./views/LandingSection";
import ProjectsSection from "./views/ProjectsSection";
import ArtSection from "./views/ArtSection";
import NavBar from "./components/NavBar";
import AboutMeSection from "./views/AboutMeSection";
import Footer from "./components/Footer";
import Skeleton from "@mui/material/Skeleton";
import Contact from "./components/Contact";
import React, { useState, useEffect } from "react";
import { PROJECT_DATA } from "./constants/data";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

const landingImg =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/website_landing.png";
const landingImgSmall =
  "https://raw.githubusercontent.com/12345Mandy/images/main/used_images/website_landing_small.png";

function App() {
  //https://stackoverflow.com/questions/66136068/how-to-have-a-loading-screen-until-all-my-components-are-mounted-in-react
  // const { loading = false } = {};
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("700"));
  const [loadedLandingImage, setLoadedLandingImage] = useState(null);
  const [isProjectsLoaded, setIsProjectsLoaded] = React.useState(false);

  const [loadedImages, setLoadedImages] = useState([]);

  // useEffect(() => {
  //   const preloadImages = async () => {
  //     const loaded = await Promise.all(
  //       PROJECT_DATA.map((currProjectData) => {
  //         return new Promise((resolve) => {
  //           const currLoadedImg = new Image();
  //           currLoadedImg.src = currProjectData.img;
  //           currLoadedImg.onload = () => resolve(currProjectData);
  //         });
  //       })
  //     );
  //     setLoadedImages(loaded);
  //     props.setIsProjectsLoaded(true);
  //   };

  //   preloadImages();
  // }, []);

  // const removeLoading = () => {
  //   setIsLoading(false);
  // };

  // // const handleLoading = () => {
  // //   window.addEventListener("load", removeLoading);
  // //   return () => window.removeEventListener("load", removeLoading);
  // // };

  // useEffect(() => {
  //   setTimeout(
  //     () => window.addEventListener("load", setIsLoading(false)),
  //     3100
  //   );
  //   console.log("loadingh");
  //   console.log(isLoading);
  // }, []);
  useEffect(() => {
    const currLoadedImg = new Image();
    if (isMobile) {
      currLoadedImg.src = landingImgSmall;
    } else {
      currLoadedImg.src = landingImg;
    }
    setLoadedLandingImage(currLoadedImg);
    const preloadImages = async () => {
      const loaded = await Promise.all(
        PROJECT_DATA.map((currProjectData) => {
          return new Promise((resolve) => {
            const currLoadedImg = new Image();
            currLoadedImg.src = currProjectData.img;
            currLoadedImg.onload = () => resolve(currProjectData);
          });
        })
      );
      setLoadedImages(loaded);
    };

    preloadImages();
    setIsLoading(false);
  }, []);

  // useEffect(() => {
  //   console.log("loadingh");
  //   console.log(isLoading);
  //   // setTimeout(() => {
  //   //   setIsLoading(false);
  //   // }, 1350);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  //   // window.onLoad = setTimeout(() => {
  //   //   console.log("hai");
  //   // }, 3000);
  // }, []);

  function renderPage() {
    if (loadedLandingImage !== null && loadedImages.length > 0) {
      return (
        <div className="App">
          <NavBar />
          <div id={"landing-section"}>
            <LandingSection loadedLandingImage={loadedLandingImage} />
          </div>
          <div id={"about-section"}>
            <AboutMeSection />
          </div>

          {/* <div style={{ clear: "both" }}></div> */}

          <div id={"project-section"}>
            <ProjectsSection loadedImages={loadedImages} />
          </div>

          <div id={"art-section"}>
            <ArtSection />
          </div>

          {/* <Contact/> */}
          <Footer />
        </div>
      );
    } else {
      return (
        <div className="loader">
          <h1 className="loading-text">LOADING...</h1>
          <div className="drop"></div>
          <div className="wave"></div>
        </div>
      );

      // <div className="loader">
      //   <h1 className="loading-text">LOADING...</h1>
      // </div>
      //     <svg
      //       width="500"
      //       viewBox="0 0 798 586"
      //       // fill="none"
      //       fill="#4F6168"
      //       xmlns="http://www.w3.org/2000/svg"
      //     >
      //       <g transform="translate(0.000000,586.000000) scale(0.100000,-0.100000)">
      //         <path
      //           class="signature_base"
      //           d="M3693,4368c-44-49-50-74-87-333c-51-358-78-516-143-848l-66-338l-40-21c-58-29-250-75-391-94
      // c-218-29-553-2-761,61c-44,13-134,42-200,65c-275,93-475,130-705,130c-134,0-172-4-254-25c-183-46-270-105-328-223
      // c-29-59-33-76-33-152c0-75,3-91,30-138c34-63,72-88,100-68c19,14,19,16-10,116c-36,128,29,256,165,325c100,51,168,65,331,72
      // c230,9,392-18,696-117c292-96,488-130,747-130c198,0,331,14,485,51c69,16,128,26,133,21c4-4-3-64-17-133c-27-130-75-446-75-490
      // c0-14,6-34,14-44c11-16,18-17,38-8s29,28,51,114c41,158,128,318,224,414c53,54,88,59,104,18c13-34,6-134-21-292
      // c-11-62-20-132-20-155c0-105,48-176,119-176c64,0,117,56,188,196c32,63,61,114,64,114s11-37,18-82c29-193,113-272,217-207
      // c46,28,77,76,168,258c79,157,107,206,134,229c31,24,68,14,124-34c29-26,72-55,95-65c77-35,587-105,943-129c188-13,678-12,800,0
      // c207,22,356,84,466,194c117,118,168,250,146,383c-24,147-122,275-259,340c-103,50-183,60-309,40c-152-24-244-73-244-128
      // c0-24,9-23,110,5c107,31,259,14,377-41c157-73,238-255,173-388c-28-59-109-147-163-178c-58-34-169-72-247-86c-113-19-419-41-575-41
      // c-314,0-1141,76-1208,112c-12,6-39,26-61,45c-143,129-241,67-397-250c-71-145-109-197-144-197c-64,0-92,117-80,340
      // c12,220,12,221-8,234c-30,19-47-5-96-134c-70-188-127-309-169-365c-72-95-84-57-57,174c34,278,3,391-108,391c-56,0-100-25-157-87
      // c-28-31-54-53-57-49c-7,6,26,199,36,213c3,4,33,26,65,48c141,95,247,280,315,552c59,234,65,283,65,508c1,205,0,212-28,295
      // c-36,107-51,137-89,178C3788,4399,3726,4403,3693,4368z M3785,4284c57-87,85-308,66-507c-30-298-112-590-206-731
      // c-39-59-102-123-111-113c-4,4,14,123,39,264c61,343,62,354,92,608c48,426,54,464,65,484C3745,4317,3764,4315,3785,4284z"
      //           stroke="#4c626a"
      //           stroke-width="25"
      //         />
      //         <path
      //           class="signature_dash"
      //           d="M4124,2835c-4-9-2-21,4-27c16-16,47-5,47,17C4175,2851,4133,2859,4124,2835z"
      //           stroke="#4c626a"
      //           stroke-width="100"
      //         />
      //       </g>
      //     </svg>
    }
  }

  // return !isLoading ? (
  return renderPage();
}

export default App;
