import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material/styles";
import "./ProjectsSection.css";
import ProjectCard from "../components/ProjectCard";
import test from "../images/name.svg";
import { ART_DATA } from "../constants/data";
import ArtSVG from "../images/Art.svg";
import { useState, useEffect } from "react";

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

export default function ArtSection() {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const preloadImages = async () => {
      const loaded = await Promise.all(
        ART_DATA.map((currProjectData) => {
          return new Promise((resolve) => {
            const currLoadedImg = new Image();
            currLoadedImg.src = currProjectData.img;
            currLoadedImg.onload = () => resolve(currProjectData);
          });
        })
      );
      setLoadedImages(loaded);
    };

    preloadImages();
  }, []);

  return (
    //   <div className="test">hi</div>
    <Box className="art" sx={{ maxWidth: "100%", minHeight: 829 }}>
      <img className="header" src={ArtSVG} alt="name" />
      <Box className="project-masonry">
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 3 }}
          spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
        >
          {loadedImages.map((item, index) => (
            <div key={index}>
              <img
                src={`${item.img}?w=162&auto=format`}
                srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                style={{
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  display: "block",
                  width: "100%",
                }}
              />
              <Label>{item.title}</Label>
            </div>
          ))}
        </Masonry>
      </Box>
    </Box>
  );
}
