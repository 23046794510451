import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material/styles";
import "./ProjectsSection.css";
import ProjectCard from "../components/ProjectCard";
// import { PROJECT_DATA } from "../constants/data";
import ProjectSVG from "../images/Projects.svg";
// import { useState, useEffect } from "react";

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

export default function ProjectsSection({ loadedImages }) {
  // const [loadedImages, setLoadedImages] = useState([]);

  // useEffect(() => {
  //   const preloadImages = async () => {
  //     const loaded = await Promise.all(
  //       PROJECT_DATA.map((currProjectData) => {
  //         return new Promise((resolve) => {
  //           const currLoadedImg = new Image();
  //           currLoadedImg.src = currProjectData.img;
  //           currLoadedImg.onload = () => resolve(currProjectData);
  //         });
  //       })
  //     );
  //     setLoadedImages(loaded);
  //     props.setIsProjectsLoaded(true);
  //   };

  //   preloadImages();
  // }, []);

  return (
    //   <div className="test">hi</div>
    <Box className="projects" sx={{ maxWidth: "100%", minHeight: 829 }}>
      <img className="header" src={ProjectSVG} alt="name" />
      {/* <Masonry columns={3} spacing={2}>
        {itemData.map((item, index) => (
          <div key={index}>
            <Label>{index + 1}</Label>
            <img
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: "block",
                width: "100%",
              }}
            />
          </div>
        ))}
      </Masonry> */}
      <Box className="project-masonry-tweaked">
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 3 }}
          spacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
        >
          {loadedImages.map((item, index) => (
            <div key={index}>
              <ProjectCard props={item}></ProjectCard>
            </div>
          ))}
        </Masonry>
      </Box>
    </Box>
  );
}
