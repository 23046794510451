import { Box, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box
      height="8vh"
      //   check if ok mobile
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="background.main"
    >
      <div style={{ width: "90%" }}>
        <Typography
          variant="h6"
          color="secondary"
          style={{ display: "inline-block" }}
        >
          <b> Mandy He </b>
        </Typography>

        <Typography
          variant="h6"
          color="secondary"
          style={{ display: "inline-block" }}
        >
          <b> &nbsp;&copy;2024</b>
        </Typography>
        <Typography
          variant="h6"
          color="secondary"
          style={{ display: "inline-block" }}
        >
          <b> -Note: Currently under construction!</b>
        </Typography>

        <br />
      </div>
    </Box>
  );
}
